import * as process from 'process'

// Bolt Website config
export const SIMULATION_DOWN = process.env.NEXT_PUBLIC_SIMULATION_DOWN === 'true'

// Cloudinary
export const CLOUDINARY_CLOUD_NAME = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME as string

// Facebook
export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string

// Google Maps
export const GMAPS_API_KEY = process.env.NEXT_PUBLIC_GMAPS_API_KEY

// Mixpanel
export const MIXPANEL_ID = process.env.NEXT_PUBLIC_MIXPANEL_ID
